import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import * as PropTypes from 'prop-types'
import styled from '@emotion/styled'
import Grid from 'src/components/Grid'
import ScrollListener from 'src/components/ScrollListener'

import SEO from 'src/components/SEO'
import Collapse from 'src/components/Collapse'
import ProjectRow from 'src/components/ProjectRow'
import ProjectItem from 'src/components/ProjectItem'
import TextLockup from 'src/components/TextLockup'
import ModalTakeover from 'src/components/ModalTakeover'
import HomepageInfo from 'src/components/HomepageInfo'
import Header from 'src/components/Header'
import Footer from 'src/components/Footer'
import Container from 'src/components/Container'
import MaterialIcon from 'src/components/MaterialIcon'
import { colors, typography, animations, mediaQueries as mq } from 'src/styles'

const propTypes = {
	data: PropTypes.object.isRequired,
}

const TitleWrap = styled.div`
	height: auto;
	min-height: 50px;
	width: 100%;
	border-bottom: 1px solid ${ colors.white };
	text-align: left;
	display: flex;
	align-items: center;
	justify-content: space-between;
	h1 {
		margin: 0;
	}
	${ mq.smallAndBelow } {
		height: auto;
	}
`

const IconWrap = styled.div`
	flex-grow: 0;
	flex-shrink: 0;
`

const AboutWrap = styled.div`
	// display: flex;
	// justify-content: space-between;
	// align-items: flex-start;
	padding: 20px 0;
`

const ContactWrap = styled.div`
	padding: 20px 0;
	border-bottom: 1px solid ${ colors.white };
`

const FilterWrap = styled.div`
	width: 100%;
	span {
		margin-right: 10px;
		&:hover {
			cursor: pointer;
		}
	}
	padding-top: 20px;
	padding-bottom: 20px;
	border-bottom: 1px solid ${ colors.white };
	.filter-tag {
		width: 15%;
		flex-grow: 0;
		flex-shrink: 0;
		${ typography.bodyMedium }
		&.active {
			text-decoration: underline;
			text-decoration-color: ${ colors.hrColorStrong };
			font-family: ${ typography.serifFont };
		}

		${ mq.smallAndBelow } {
			width: 45%;
			
		}
	}
	display: flex;
	// flex-direction: column;
	flex-wrap: wrap;
`

const Navigation = styled(Container)`
	hr {
		margin: 0;
		border-color: ${ colors.textColor };
	}
`

const FilterTag = styled.div`
	background-color: ${ colors.hrColor };
	padding: 5px 15px;;
	border-radius: 100px;
	margin: 5px 10px;
	height: 30px;
	display: flex;
	align-items: center;
	.tag-name {
		font-size: 11px;
		line-height: 40px;
		margin-right: 10px;
	}
`

const FilterTitleWrap = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	padding: 20px 0;
	.h1 {
		line-height: 50px;
		margin: 0 20px;
	}

`

const ActiveFilterWrap = styled.div`
	display: flex;
`

const InfoTitleWrap = styled.div`
`

const InfoRow = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid ${ colors.white };
	height: auto;
	min-height: 50px;
	&:hover {
		cursor: pointer;
	}
`

const ProjectsContainer = styled(Container)`
	height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const ProjectsContainerOverlay = styled.div`
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	height: 20vh;
	z-index: 2;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, #191919 100%);
`

const ProjectsWrap = styled.div`
	&.video-playing {
		.video-item {
			// .video-wrapper {
			// 	transition: opacity ${ animations.mediumSpeed } ease-in-out;
			// 	&:not(.playing) {
			// 		opacity: 0.1;
			// 	}
			// }
		}
	}

	${ mq.smallAndBelow } {
		// padding: 40px 0;
		padding-bottom: 45px;
	}

`

const CustomFooter = styled.div`
	// width: 100vw;
	padding: 20px 0;
	border-top: 1px solid ${ colors.hrColor };
	display: flex;
	align-items: center;
	a {
		margin-right: 20px;
		h6 {
			margin-bottom: 0;
			span {
				font-size: 10px;
			}
		}
	}
`

const PageWrap = styled.div`
	height: 100vh;
`

class PageTemplate extends React.Component {
	constructor(props) {
    super(props)
    // create a ref to store the textInput DOM element
    this.projectsWrap = React.createRef()
    this.highlightVideoPlaying = this.highlightVideoPlaying.bind(this)
  }

	state = {
		modal: false,
		modalContent: false,
		selectedFilters: [],
		filtered: false,
		videoPlaying: false
	}

	TitleWrap = title => {
		return (
			<TitleWrap>
				<h1>{title}</h1>
				<IconWrap>
					<MaterialIcon size='24px' lineHeight="60px" >expand_more</MaterialIcon>
				</IconWrap>
			</TitleWrap>
		)
	}

	filterTitleWrap = () => {
		this.state.selectedFilters.length > 0 ? (
			this.state.selectedFilters.map((filter, index) => {
				return (
					<FilterTag key={index}><span className="tag-name">{filter}</span><MaterialIcon size="13px" lineHeight="40px" onClick={(e) => {this.removeFilter(e, filter)}}>close</MaterialIcon></FilterTag>
				)
			})
		) : false
	}

	handleFilterSelect = slug => {
		this.setState({ filtered: true })
		if (!this.state.selectedFilters.includes(slug)) {
			let tempFilters = this.state.selectedFilters
			tempFilters.push(slug)
			this.setState({ selectedFilters: tempFilters })
		}
	}

	removeFilter = (e, filter) => {
		e.stopPropagation()
		let array = [...this.state.selectedFilters]
		const index = array.indexOf(filter)
		if (index > -1) {
			array.splice(index, 1)
		}

		this.setState({ selectedFilters: array })

		if ( array.length < 1) {
			this.setState({filtered : false})
		}
	}

	resetFilter = () => {
		this.setState({ selectedFilters: [], filtered: false })
	}

	containsAll = (arr1, arr2) => {
		return arr1.every(i => arr2.includes(i))
	}

	handleModalTrigger = (type, data) => {
		// console.log('handleModalTrigger', data)
		this.setState({ modal: type })
		if (type === false) {
			setTimeout(() => {
				this.setState({ modal: false, modalContent: false })
			}, 700)
		} else {
			this.setState({ modal: type })
			if (type === 'project') {
				let title = {
					client: data.client,
					projectTitle: data.projectTitle }
				let content = {
					information: data.information,
					links: data.links,
					services: data.services,
					team: data.team
				}
				this.setState({
					modalContent: {
						type: 'project',
						title: title,
						content: content
					}
				})
			} else {
				this.setState({
					modalContent: data
				})
			}
		}
	}

	toggleModal = id => {
    if (id) {
      this.setState({ modal: id })
    } else {
      this.setState({ modal: false })
			setTimeout(() => {
				this.setState({ modalContent: false })
			}, 10000)
    }
  }

  highlightVideoPlaying = () => {
  	this.projectsWrap.current.classList.toggle('video-playing');
  	this.setState(prevState => ({
		  videoPlaying: !prevState.videoPlaying
		}));
  }

	render () {
		const site = this.props.data.allContentfulSiteSettings && this.props.data.allContentfulSiteSettings.edges[0].node
		const page = this.props.data.allContentfulPage.edges[0].node
		const filterTags = this.props.data.allContentfulFilterTag.edges

		const { about, selectClients, contact, previousWork } = page
		// console.log('page: ', previousWork )
		const aboutContent = {
			type : 'about',
			title : {
				client : 'Melanie Chernock'
			},
			content : {
				information: about,
				services: selectClients.text,
				team: previousWork
			}
		}

		const { featuredWork } = page
		// const hasAtf = sections && sections[0].__typename === 'ContentfulWideMedia' && sections[0].width === 'fullWidth'
		const seo = page.seo

		// console.log("this.state: videoPlaying::", this.state.videoPlaying);
		// console.log("page.title:::", page.title);

		return (
			<PageWrap>
				<SEO
					title={page.title}
					description={seo.description && seo.description.description}
					siteSettings={site}
					keywords={seo.keywords}
					shareImage={seo.shareImage && 'https:' + seo.shareImage.file.url}
				/>
				{/*<Header
					hasAtf={false}
					bannerText={site.bannerText}
          bannerColor={site.bannerColor}
          navigation={site.navigation}
          locations={page.slug}
          title={site.title}
          handleModalTrigger={this.handleModalTrigger}
          contact={contact}
          aboutContent={aboutContent}
          resetFilter={this.resetFilter}
          removeFilter={this.removeFilter}
          handleFilterSelect={this.handleFilterSelect}
          filterTags={filterTags}
          selectedFilters={this.state.selectedFilters}

				/>*/}

				<ProjectsContainer>
					
					<HomepageInfo
						title={aboutContent.title}
						content={aboutContent.content}
					/>

					<CustomFooter>
					<a href="https://www.linkedin.com/in/melaniechernock/" target="_blank"><h6>LinkedIn</h6></a>
					<a href="https://workingnotworking.com/2906-melanie" target="_blank"><h6>Working Not Working</h6></a>
						{/*<h6>ALL WORK &#169; MELANIE CHERNOCK <span>{new Date().getFullYear()}</span></h6>*/}
					</CustomFooter>		
				{/*
					<ProjectsContainerOverlay>
					</ProjectsContainerOverlay>
				*/}
				</ProjectsContainer>
	

				{/*<Footer {...site} />*/}

				{/*<ModalTakeover
					open={true}
					type={this.state.modalContent ? this.state.modalContent.type : false}
					title={this.state.modalContent ? this.state.modalContent.title : false}
					content={this.state.modalContent ? this.state.modalContent.content : false}
					toggleModal={this.handleModalTrigger}
				/>*/}



			</PageWrap>
		)
	}
}

PageTemplate.propTypes = propTypes

export const pageQuery = graphql`
  query($id: String!) {
		allContentfulSiteSettings(filter: {internalName: {regex: "/^((?!PLACEHOLDER).)*$/"}}) {
	    edges {
	      node {
	        ...SiteSettings
	      }
	    }
	  }
    allContentfulPage(filter: {id: {eq: $id}}) {
			edges {
				node {
					id
					title
					slug
					about {
						...TextSection
					}
					previousWork {
			    	json
					}
					selectClients {
						...TextSection
					}
					contact {
						...TextSection
					}
					seo {
						description {
							description
						}
						keywords
						shareImage {
							file {
								url
							}
						}
					}
				}
			}
    }
    allContentfulFilterTag(sort: {fields: tag, order: ASC}) {
	    edges {
	      node {
	        slug
	        tag
	      }
	    }
	  }

	}
`

export default PageTemplate
